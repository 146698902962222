import { useRoutes, Navigate } from 'react-router-dom';
import HomePage from '../components/HomePage';
import AppPrivacy from '../components/AppPrivacy';
import AppTerms from '../components/AppTerms';



export default function Routes() {

    const RoutesData = [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/privacy',
            element: <AppPrivacy />
        },
        {
            path: '/terms',
            element: <AppTerms />
        },
        {
            path: '/',
            element: <Navigate to="/" />
        }
    ];

    return useRoutes(RoutesData);
}