import { useMediaQuery } from 'react-responsive';
import AppHome from './AppHome';
import AppFeatures from './AppFeatures';
import AppDownload from './AppDownload';
import AppCookie from './AppCookie';

function HomePage() {
    const mobileActive = useMediaQuery({ maxWidth: 960 })
    return (
        <>
            <AppHome mobileActive={mobileActive} />
            <AppFeatures mobileActive={mobileActive} />
            <AppDownload mobileActive={mobileActive} />
            <AppCookie mobileActive={mobileActive} />
        </>
    )
}

export default HomePage;
