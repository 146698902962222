import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

// import icon1 from '../../assets/icon1.svg'
// import icon2 from '../../assets/icon2.svg'
// import icon3 from '../../assets/icon3.svg'
// import icon4 from '../../assets/icon4.svg'

import icon1 from '../../assets/create-communication.png'
import icon2 from '../../assets/join-communities.png'
import icon3 from '../../assets/invite-people.png'
import icon4 from '../../assets/chat-with-people.png'

const features = [
    { "icon": icon1, "title": "Create Communities", "description": "Create ANY community you want! Be it a small school club, a church or social group." },
    { "icon": icon2, "title": "Join Communities", "description": "Join communities via community link. Find and connect with new people from all over the world." },
    { "icon": icon3, "title": "Invite People", "description": "Invite people to be apart of your community. Organize events and meet-ups with them." },
    { "icon": icon4, "title": "Chat With People", "description": "Got something to discuss with someone in your community? Chat on!" },
]

function AppFeatures({ mobileActive }) {
    return (
        <div className="bg-primary text-light">
            <Container className={`${mobileActive ? "py-5 px-4" : "p-5"}`}>
                <h2 className="mb-5 display-4 fw-bold">We are not just an app.</h2>
                <Row xs="1" lg="2">
                    {features.map(feature => (
                        <Col key={feature.title} className="d-flex align-items-start my-4">
                            <div className="p-4 me-5 bg-light rounded-circle">
                                <Image src={feature["icon"]} width="24" height="24" />
                            </div>
                            <div className="flex-fill">
                                <h4>{feature["title"]}</h4>
                                <p className="my-2">{feature["description"]}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

export default AppFeatures
