import CookieConsent from "react-cookie-consent";

function AppCookie({ mobileActive }) {
    return (
        <CookieConsent
            id="cookieconsent"
            contentStyle={{ textAlign: "center" }}
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#3880FF" }}
            buttonStyle={{ display: 'none' }}
            expires={150}
        >
            This website uses cookies to ensure you get the best experience on our website.{" "}
            <a style={{ color: 'white' }} href="/privacy" target="_blank" rel="noreferrer">View Privacy Policy</a>
            { mobileActive && <br /> }
            <button
                id="rcc-decline-button"
                aria-label="Decline cookies"
                onClick={() => document.getElementsByClassName('CookieConsent')[0].style.visibility = 'hidden'}
                style={{
                    background: 'rgb(56, 128, 255)',
                    border: 'none',
                    color: 'white',
                    cursor: 'pointer',
                    flex: '0 0 auto',
                    padding: '10px 5px 0 30px',
                    margin: mobileActive ? '5px 15px' : '0 15px',
                }}>Reject</button>
            <button
                id="rcc-confirm-button"
                aria-label="Accept cookies"
                onClick={() => document.getElementsByClassName('CookieConsent')[0].style.visibility = 'hidden'}
                className="btn"
                style={{
                    background: 'white',
                    border: 'none',
                    boxShadow: 'none',
                    color: 'black',
                    cursor: 'pointer',
                    flex: '0 0 auto',
                    padding: '5px 10px',
                    margin: '0 15px',
                }}>
                Accept</button>
        </CookieConsent>
    )
}

export default AppCookie;
