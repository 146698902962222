import React from 'react'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faDribbble, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

// import { ReactComponent as LogoDark } from '../../assets/logoDark.svg'
import Logo from '../../assets/logo-with-name-dark.png'

function AppFooter({ mobileActive }) {
    return (
        <div className="bg-dark text-light">
            <Container className={`${mobileActive ? "p-4" : "px-5 py-4"}`}>
                <div className="d-flex flex-column align-items-center border-1 border-bottom border-secondary">
                    <Nav className="mx-auto mb-4">
                        <img src={Logo} alt="Hello Community" height="60" width="210" style={{ marginRight: '10px' }} />
                        {/* <h3>Hello Community</h3> */}
                    </Nav>

                    <Nav className="mx-auto mb-4">
                        <Nav.Link href="/terms" className="link-light">Terms {"&"} conditions</Nav.Link>
                        <Nav.Link href="/privacy" className="link-light">Privacy Policy</Nav.Link>
                    </Nav>
                </div>
                <div className="d-flex mt-4">
                    <p className="my-auto me-auto" style={{ fontSize: "14px", color: "#D9DBE1" }}>© 2022 Hello Community Inc. All rights reserved</p>
                    <div className="d-flex">
                        <Button hidden variant="dark" size="sm" className="ms-1 btn-circle rounded-circle "><FontAwesomeIcon icon={faInstagram} /></Button>
                        <Button hidden variant="dark" size="sm" className="ms-1 btn-circle rounded-circle "><FontAwesomeIcon icon={faDribbble} /></Button>
                        <Button hidden variant="dark" size="sm" className="ms-1 btn-circle rounded-circle "><FontAwesomeIcon icon={faTwitter} /></Button>
                        <Button hidden variant="dark" size="sm" className="ms-1 btn-circle rounded-circle "><FontAwesomeIcon icon={faYoutube} /></Button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AppFooter
