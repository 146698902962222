/* eslint-disable jsx-a11y/anchor-is-valid */
import './App.css';

import { useMediaQuery } from 'react-responsive'
import { BrowserRouter } from 'react-router-dom';

import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import Routes from './routes';

function App() {
  const mobileActive = useMediaQuery({ maxWidth: 960 })

  return (
    <BrowserRouter>
      <AppHeader mobileActive={mobileActive} />
      <Routes />
      <AppFooter mobileActive={mobileActive} />
    </BrowserRouter>
  );
}

export default App;
