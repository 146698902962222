import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { Player } from '@lottiefiles/react-lottie-player'

function AppHome({ mobileActive }) {
    return (
        <header className="min-vh-90 d-flex align-items-center">
            <Container className={`${mobileActive ? "py-5 px-4" : "p-5"} h-100`}>
                <Row xs="1" lg="2" className="h-100 align-items-center">
                    <Col>
                        <p className={`${mobileActive ? "text-break display-2" : "h1 display-1"} fw-bolder w-100`}>Communities made easy.</p>
                        <p className={`mt-3 ${mobileActive ? "fs-6" : "fs-5"}`}>Managing communities has never been easier. Easily create or join a community in seconds and start interacting with people in your communities!</p>
                        <Button href='#features' variant="primary" className="mt-4" style={{ padding: "1rem 2rem", borderRadius: "8px" }}>Learn More</Button>
                    </Col>
                    <Col xs={{ order: 'first' }} lg={{ order: 'last' }}>
                        <Player
                            autoplay
                            keepLastFrame
                            src="https://assets2.lottiefiles.com/packages/lf20_qAEc0l.json"
                            style={{ height: '100%', width: 'auto' }}
                        />
                    </Col>
                </Row>
                <div id='features'></div>
            </Container>
        </header>
    )
}

export default AppHome
