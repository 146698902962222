import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

import { ReactComponent as GooglePlay } from '../../assets/googlePlay.svg'
import { ReactComponent as AppStore } from '../../assets/appStore.svg'
import figure from '../../assets/figure1.png'

function AppDownload({ mobileActive }) {
    return (
        <Container className={`${mobileActive?"py-5 px-4":"p-5"}`}>
            <Row xs="1" md="2" className="align-items-center" >
                <Col>
                    <h2 className={`${mobileActive ? "display-2" : "display-4"} fw-bolder`}>Download the app now!</h2>
                    <h5 className={`${mobileActive?"fs-6":""} mb-5 fw-light lh-lg w-100`}>Create and manage communities easily. Simplified environment to keep private community members interacting and updated. Plan and host events, create discussion threads and much more!</h5>
                    <h5 className={`${mobileActive?"fs-6":""} fw-bold`}>Get the App.</h5>
                    <div className="d-flex flex-wrap">
                        <Button href="https://play.google.com/store/apps/details?id=com.hello.community.app" target="_blank" className="me-2 mb-2 p-0" style={{ borderRadius: "8px" }}><GooglePlay /></Button>
                        <Button href="https://apps.apple.com/us/app/hello-community/id1670273114" target="_blank" className="me-2 mb-2 p-0" style={{ borderRadius: "8px" }}><AppStore /></Button>
                    </div>
                </Col>
                <Col xs={{ order: 'first' }} md={{ order: 'last' }}>
                    <Image src={figure} className="w-100 h-auto" />
                </Col>
            </Row>
        </Container>
    )
}

export default AppDownload
