import React from 'react'

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

import Logo from '../../assets/logo-with-name.png'
// import WebApp from '../../assets/webapp.png'

import { ReactComponent as GooglePlay } from '../../assets/googlePlay.svg'
import { ReactComponent as AppStore } from '../../assets/appStore.svg'

function AppHeader({ mobileActive }) {
    return (
        <Navbar bg="white" expand="md" className="border-bottom sticky-top">
            <Container className={`${mobileActive ? "px-4 py-0" : "px-5 py-2"}`}>
                <Navbar.Brand href="/" className="py-0" style={{ fontSize: mobileActive ? '1rem' : '1.25rem' }}>
                    <img src={Logo} alt="Hello Community" height='60' width='210' />
                </Navbar.Brand>
                <Nav className="flex-row">
                    <Nav.Link target="_blank" href="https://play.google.com/store/apps/details?id=com.hello.community.app">
                        {mobileActive ?
                            <Button variant="dark" size="lg" className="ms-2" ><FontAwesomeIcon icon={faGooglePlay} /></Button>
                            :
                            <GooglePlay />
                        }
                    </Nav.Link>
                    <Nav.Link target="_blank" href="https://apps.apple.com/us/app/hello-community/id1670273114">
                        {mobileActive ?
                            <Button variant="dark" size="lg" className="ms-2" ><FontAwesomeIcon icon={faApple} /></Button>
                            :
                            <AppStore />
                        }
                    </Nav.Link>
                    {/* <Nav.Link href="https://web.hellocommunityapp.com">
                        {mobileActive ?
                            <Button variant="dark" size="lg" className="ms-2" ><FontAwesomeIcon icon={faChrome} /></Button>
                            :
                            <img src={WebApp} title="Web App" alt="Web App" height="40" width="135" />
                        }
                    </Nav.Link> */}
                </Nav>
            </Container>
        </Navbar>
    )
}

export default AppHeader
